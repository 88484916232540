@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.App {
  margin-top: 50px
}
p, span,  li, button, label {
  font-family: 'Source Sans 3', sans-serif !important;
}


h1, h2, h3, h4, h5, h6, a {
  font-family: 'Readex Pro', sans-serif !important;
}

body {
  background: linear-gradient(22deg, rgba(51,51,49,1) 0%, rgba(71,71,71,1) 86%, rgba(89,89,89,1) 100%) !important;

}