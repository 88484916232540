
.objectifMontre {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 88%;
}


.montre{
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    width: 400px;
    transform: rotate(45deg);
    overflow: hidden;
    box-shadow: 0 0 20px black;
}

.montre figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}

.montre figure:hover::before {
	-webkit-animation: shine 2s;
	animation: shine 2s;
}

.montre img{
    max-width: 100%;
    transform: rotate(-45deg) scale(1.42);
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

@media screen and (max-width: 1200px) {
	.montre {
		display: none !important;
	}
	.objectifMontre {
		grid-template-columns: auto !important;
	}
}
