.bijouxImage {
    max-width: 100%;
    width: 500px;
    max-height: 300px;
}


.titleBijouxImage {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1204px) {
    #bijouxSection {
        margin-top: 500px !important;
    }

    .bouclesOrreilles {
        overflow: auto;
        height: 80%;
    }

    .Bracelets {
        overflow: auto;
        height: 80%;
    }
}

.sessionBijoux {
    display: grid;
    grid-gap: 20px;
    margin-top: 100px;
    grid-template-columns: repeat(2, 1fr);
    padding: 116px 40px 21px 51px;
}

@media screen and (max-width: 960px) {
    figcaption {
        width: 100% !important;
        font-size: 12px !important;
    }

    .bijouxImage {
        max-width: 76% !important;
    }
    .sessionBijoux {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .titleBijouxImage {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .bouclesOrreilles {
        overflow: inherit;
    }

    .Bracelets {
        overflow: inherit;
    }
}