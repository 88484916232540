.googleMapReact {
  height: 64vh;
  width: 80%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
       filter: grayscale(100%);
}


.mapGoogle {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 16%;
}

@media screen and (max-width: 960px) {
  .contact-text {
    font-size: 12px;
    line-height: 23px !important;
  }
  .mapGoogle {
  grid-template-columns: repeat(1, 1fr) !important;

  }
  .googleMapReact {
    width: 95% !important;
  }
}