

label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}


.buttonSubmit {
    width: 100%;
    display: flex;
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important; 
}

input[type=submit] {
    background-color: #949189;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    width: 62%;
    justify-content: center;
}

input[type=submit]:hover {
    background-color: #706e69;
    transition: .5s;
}

.col-25 {
    float: left;
    width: 22%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 73%;
    margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row {
    display: flex;
    flex-direction: column;

}

#formSubscribe {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}

.row {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
}

.form-control, .btn-primary , .form-select   {
    color: white !important;
    background: #333331 !important;
}

#adress {
    width: 205%;
}
.btn-primary {
    border: 1px solid #ffffff !important;
    width: 52%;
    margin: 0 20%;
    padding: 10px;
    cursor: pointer;
}

.btn-primary:hover {
    border: 1px solid #afafaf !important;
    background: rgb(44, 44, 44) !important;
    transition: .5s !important;
}

.sectionForm {
    width: 100%;
    margin: 2% auto;
    background-image: url('../public/pat.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400%;
    border-radius: 6px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.warning {
    display: none;
}

.items {
    margin-bottom: 15px;
    margin-top: 18px;
}
@media screen and (min-width: 1071px) and (max-width: 1200px) {
    .sectionForm {
        margin: 23% auto !important;
        width: 75% !important;
    }
    p {
        font-size: 3vmin !important;
      }
}


@media screen and (max-width: 1016px) {
    .sectionForm {
        margin: 12% auto !important;
        /* width: 37% !important; */ 
        width: 62% !important;
      }
      section {
          padding: 10px !important;
          /* margin-top: 54px !important; */
          width: 100% !important;
      }
      p {
        font-size: 4vmin !important;
      }
      form {
        width: 134% !important;
      }
      .topnav .itemNav {
        width: 150%;
        margin-left: -15px;
    }
}

@media screen and (min-width: 694px) and (max-width: 1071px) {
    p {
        font-size: 3.2vmin !important;
      }
}