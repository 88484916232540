.privacy h1 {
    font-family: bebas neue;
    font-size: 46px;
    text-align: center;
    color: rgb(241, 239, 223);
    margin: 0;
    padding: 0 0 6px 0;
  }
  .privacy h2 {
    font-family: bebas neue;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: rgb(241, 239, 223);
    margin: 0;
    padding: 40px 0 0 0;
  }
  .privacy p {
    font-family: open sans;
    font-size: 13px;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 10px 0 0 0;
    line-height: 30px;
  }