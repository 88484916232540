.devis {
    background: #949189;
    padding: 117px;
    text-align: center;
    color: white;
}

.buttonDevis {
    color: #949189;
    background-color: white;
    margin: 0 0 0 2vw;
    margin: 0 0px 0 2vw;
    width: 9%;
    height: 46px;
    border: none;
    cursor: pointer;
}

.buttonDevis:hover {
    background-color: rgb(233, 233, 233);
    transition: .5s;
}


