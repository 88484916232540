section {
    display: flex;
    justify-content: center;
    padding: 42px;
    justify-items: center;
    color: white;
}

.objectif {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 88%;
}


.objectif img {
    width: 900px;
    max-width: 700px;
    height: 500px;
    object-fit: cover;
    padding: 5px;
    border: 1px solid rgb(121, 121, 121);
    box-shadow: 0 0 20px black;
}

.buttonEnSavoirPlus {
    color: #fff;
    background-color: #949189;
    color: #fff;
    background-color: #949189;
    border-color: #949189;
    margin: 0 0px 0 2vw;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
}

section {
    display: grid;
    padding: 116px 163px 21px 151px;
}

.sectionArticle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 571px) and (max-width: 1200px) {
    /* section {
        padding: 0px 0px 0px 0px !important;
    } */

    .sectionArticle {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 20px !important;
    }

    .objectif {
        grid-template-columns: 1fr !important;
        width: 100% !important;
        justify-items: center;
    }

    .objectif img {
        width: 578px;
        max-width: 503px;
        height: 374px;
    }

    .devis {
        padding: 5px !important;
    }
    .subTextSection {
        display: grid;
        justify-content: center;
        justify-items: center;
        padding: 13px;
    }
    .buttonDevis {
        width: 32% !important;
    }
}

@media screen and (max-width: 570px) {
    /* section {
        padding: 0px 0px 0px 0px !important;
    } */

    .sectionArticle {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 20px !important;
    }

    .objectif {
        grid-template-columns: 1fr !important;
        width: 100% !important;
        justify-items: center;
    }

    .objectif img {
        max-width: 331px;
        height: 300px;
    }

    .subTextSection {
        display: grid;
        justify-content: center;
        justify-items: center;
        padding: 13px;
    }

    .devis {
        padding: 5px !important;
    }
    .buttonDevis {
        width: 62% !important;
    }
}