@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin-top: 50px
}
p, span,  li, button, label {
  font-family: 'Source Sans 3', sans-serif !important;
}


h1, h2, h3, h4, h5, h6, a {
  font-family: 'Readex Pro', sans-serif !important;
}

body {
  background: linear-gradient(22deg, rgba(51,51,49,1) 0%, rgba(71,71,71,1) 86%, rgba(89,89,89,1) 100%) !important;

}
#navbar {
    background: linear-gradient(22deg, rgba(51, 51, 49, 1) 0%, rgba(71, 71, 71, 1) 86%, rgba(89, 89, 89, 1) 100%) !important;
    color: rgb(13, 26, 38);
    position: fixed;
    top: 0;
    height: 80px;
    line-height: 60px;
    width: 100vw;
    z-index: 10;
    padding: 10px;
    box-shadow: 5px 5px 20px;
}

.nav-wrapper {
    margin: auto;
    text-align: center;
    width: 70%;
}

@media(max-width: 768px) {
    .nav-wrapper {
        width: 90%;
    }
}

@media(max-width: 638px) {
    .nav-wrapper {
        width: 100%;
    }
}


#navbar .button-devis {
    color: white;
    background: #949189;
    text-decoration: none;
    border-radius: 2px;
    padding-right: 13px;
    padding-left: 13px;
    cursor: pointer;
}

#navbar .button-devis:hover {
    color: black;
    background: #949189;
    text-decoration: none;
    border-radius: 2px;
    padding-right: 13px;
    padding-left: 13px;
    cursor: pointer;
}

.logo {
    float: left;
    font-size: 1.5em;
    height: 60px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

#navbar ul {
    display: inline-block;
    position: absolute;
    right: 6px;
    list-style: none;
    /* margin-right: 14px; */
    margin-top: -2px;
    text-align: right;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out;
}

@media(max-width: 640px) {
    #navbar ul {
        display: none;
    }
}

@media(orientation: landscape) {
    #navbar ul {
        display: inline-block;
    }
}

#navbar li {
    display: inline-block;
}

#navbar li a {
    color: white;
    display: block;
    font-size: 0.9em;
    height: 50px;
    letter-spacing: 1px;
    margin: 0 20px;
    padding: 0 4px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

#navbar li a:hover {
    /* border-bottom: 1px solid rgb(28, 121, 184); */
    color: #A98D4C;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

/* Animated Bottom Line */
#navbar li a:before,
#navbar li a:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: -1px;
    background: #A98D4C;
}

#navbar li a:before {
    left: 0;
    transition: 0.5s;
}

#navbar li a:after {
    background: rgb(13, 26, 38);
    right: 0;
    /* transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1); */
}

#navbar li a:hover:before {
    background: rgb(13, 26, 38);
    width: 100%;
    transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

#navbar li a:hover:after {
    background: transparent;
    width: 100%;
    /* transition: 0s; */
}



/*======================================================
                      Mobile Menu Menu Icon
    ======================================================*/
@media(max-width: 640px) {
    .menuIcon {
        cursor: pointer;
        display: block;
        position: fixed;
        right: 15px;
        top: 20px;
        height: 23px;
        width: 27px;
        z-index: 12;
    }

    /* Icon Bars */
    .icon-bars {
        background: rgb(13, 26, 38);
        position: absolute;
        left: 1px;
        top: 45%;
        height: 2px;
        width: 20px;
        transition: 0.4s;
    }

    .icon-bars::before {
        background: rgb(13, 26, 38);
        content: '';
        position: absolute;
        left: 0;
        top: -8px;
        height: 2px;
        width: 20px;
        /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
        transition: 0.3s width 0.4s;
    }

    .icon-bars::after {
        margin-top: 0px;
        background: rgb(13, 26, 38);
        content: '';
        position: absolute;
        left: 0;
        bottom: -8px;
        height: 2px;
        width: 20px;
        /*     -webkit-transition: top 0.2s ease 0.3s;
      transition: top 0.2s ease 0.3s; */
        transition: 0.3s width 0.4s;
    }

    /* Bars Shadows */
    .icon-bars.overlay {
        background: rgb(97, 114, 129);
        background: rgb(183, 199, 211);
        width: 20px;
        animation: middleBar 3s infinite 0.5s;
        -webkit-animation: middleBar 3s infinite 0.5s;
    }

    @keyframes middleBar {
        0% {
            width: 0px
        }

        50% {
            width: 20px
        }

        100% {
            width: 0px
        }
    }

    @-webkit-keyframes middleBar {
        0% {
            width: 0px
        }

        50% {
            width: 20px
        }

        100% {
            width: 0px
        }
    }

    .icon-bars.overlay::before {
        background: rgb(97, 114, 129);
        background: rgb(183, 199, 211);
        width: 10px;
        animation: topBar 3s infinite 0.2s;
        -webkit-animation: topBar 3s infinite 0s;
    }

    @keyframes topBar {
        0% {
            width: 0px
        }

        50% {
            width: 10px
        }

        100% {
            width: 0px
        }
    }

    @-webkit-keyframes topBar {
        0% {
            width: 0px
        }

        50% {
            width: 10px
        }

        100% {
            width: 0px
        }
    }

    .icon-bars.overlay::after {
        background: rgb(97, 114, 129);
        background: rgb(183, 199, 211);
        width: 15px;
        animation: bottomBar 3s infinite 1s;
        -webkit-animation: bottomBar 3s infinite 1s;
    }

    @keyframes bottomBar {
        0% {
            width: 0px
        }

        50% {
            width: 15px
        }

        100% {
            width: 0px
        }
    }

    @-webkit-keyframes bottomBar {
        0% {
            width: 0px
        }

        50% {
            width: 15px
        }

        100% {
            width: 0px
        }
    }


    /* Toggle Menu Icon */
    .menuIcon.toggle .icon-bars {
        top: 5px;
        -webkit-transform: translate3d(0, 5px, 0) rotate(135deg);
                transform: translate3d(0, 5px, 0) rotate(135deg);
        transition-delay: 0.1s;
        transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .menuIcon.toggle .icon-bars::before {
        top: 0;
        transition-delay: 0.1s;
        opacity: 0;
    }

    .menuIcon.toggle .icon-bars::after {
        top: 10px;
        -webkit-transform: translate3d(0, -10px, 0) rotate(-270deg);
                transform: translate3d(0, -10px, 0) rotate(-270deg);
        transition-delay: 0.1s;
        transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .menuIcon.toggle .icon-bars.overlay {
        width: 20px;
        opacity: 0;
        transition: all 0s ease 0s;
    }
}


/*======================================================
                     Responsive Mobile Menu 
    ======================================================*/
.overlay-menu {
    background: linear-gradient(22deg, rgba(51, 51, 49, 1) 0%, rgba(71, 71, 71, 1) 86%, rgba(89, 89, 89, 1) 100%);
    color: rgb(13, 26, 38);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 15px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    width: 100vw;
    height: 100vh;
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    z-index: 10;
}

.overlay-menu ul,
.overlay-menu li {
    display: block;
    position: relative;
}

.overlay-menu li a {
    display: block;
    font-size: 1.8em;
    letter-spacing: 4px;
    /*   opacity: 0; */
    padding: 10px 0;
    text-align: right;
    text-transform: uppercase;
    transition: color 0.3s ease;
    color: white;
    /*   -webkit-transition: 0.2s opacity 0.2s ease-out;
    transition: 0.2s opacity 0.2s ease-out; */
}

.overlay-menu li a:hover,
.overlay-menu li a:active {
    color: #A98D4C;
    transition: color 0.3s ease;
}
section {
    display: flex;
    justify-content: center;
    padding: 42px;
    justify-items: center;
    color: white;
}

.objectif {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 88%;
}


.objectif img {
    width: 900px;
    max-width: 700px;
    height: 500px;
    object-fit: cover;
    padding: 5px;
    border: 1px solid rgb(121, 121, 121);
    box-shadow: 0 0 20px black;
}

.buttonEnSavoirPlus {
    color: #fff;
    background-color: #949189;
    color: #fff;
    background-color: #949189;
    border-color: #949189;
    margin: 0 0px 0 2vw;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
}

section {
    display: grid;
    padding: 116px 163px 21px 151px;
}

.sectionArticle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 571px) and (max-width: 1200px) {
    /* section {
        padding: 0px 0px 0px 0px !important;
    } */

    .sectionArticle {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 20px !important;
    }

    .objectif {
        grid-template-columns: 1fr !important;
        width: 100% !important;
        justify-items: center;
    }

    .objectif img {
        width: 578px;
        max-width: 503px;
        height: 374px;
    }

    .devis {
        padding: 5px !important;
    }
    .subTextSection {
        display: grid;
        justify-content: center;
        justify-items: center;
        padding: 13px;
    }
    .buttonDevis {
        width: 32% !important;
    }
}

@media screen and (max-width: 570px) {
    /* section {
        padding: 0px 0px 0px 0px !important;
    } */

    .sectionArticle {
        grid-template-columns: 1fr;
    }

    h1 {
        font-size: 20px !important;
    }

    .objectif {
        grid-template-columns: 1fr !important;
        width: 100% !important;
        justify-items: center;
    }

    .objectif img {
        max-width: 331px;
        height: 300px;
    }

    .subTextSection {
        display: grid;
        justify-content: center;
        justify-items: center;
        padding: 13px;
    }

    .devis {
        padding: 5px !important;
    }
    .buttonDevis {
        width: 62% !important;
    }
}


label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}


.buttonSubmit {
    width: 100%;
    display: flex;
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 0 !important; 
}

input[type=submit] {
    background-color: #949189;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    width: 62%;
    justify-content: center;
}

input[type=submit]:hover {
    background-color: #706e69;
    transition: .5s;
}

.col-25 {
    float: left;
    width: 22%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 73%;
    margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row {
    display: flex;
    flex-direction: column;

}

#formSubscribe {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}

.row {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
}

.form-control, .btn-primary , .form-select   {
    color: white !important;
    background: #333331 !important;
}

#adress {
    width: 205%;
}
.btn-primary {
    border: 1px solid #ffffff !important;
    width: 52%;
    margin: 0 20%;
    padding: 10px;
    cursor: pointer;
}

.btn-primary:hover {
    border: 1px solid #afafaf !important;
    background: rgb(44, 44, 44) !important;
    transition: .5s !important;
}

.sectionForm {
    width: 100%;
    margin: 2% auto;
    background-image: url(/static/media/pat.6cafa88f.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 400%;
    border-radius: 6px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.warning {
    display: none;
}

.items {
    margin-bottom: 15px;
    margin-top: 18px;
}
@media screen and (min-width: 1071px) and (max-width: 1200px) {
    .sectionForm {
        margin: 23% auto !important;
        width: 75% !important;
    }
    p {
        font-size: 3vmin !important;
      }
}


@media screen and (max-width: 1016px) {
    .sectionForm {
        margin: 12% auto !important;
        /* width: 37% !important; */ 
        width: 62% !important;
      }
      section {
          padding: 10px !important;
          /* margin-top: 54px !important; */
          width: 100% !important;
      }
      p {
        font-size: 4vmin !important;
      }
      form {
        width: 134% !important;
      }
      .topnav .itemNav {
        width: 150%;
        margin-left: -15px;
    }
}

@media screen and (min-width: 694px) and (max-width: 1071px) {
    p {
        font-size: 3.2vmin !important;
      }
}
.footer-dark {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#282d32;
  }
  
  .footer-dark h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .footer-dark ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.8;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .footer-dark .item.social > a {
    font-size:20px;
    width:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }
  
.devis {
    background: #949189;
    padding: 117px;
    text-align: center;
    color: white;
}

.buttonDevis {
    color: #949189;
    background-color: white;
    margin: 0 0 0 2vw;
    margin: 0 0px 0 2vw;
    width: 9%;
    height: 46px;
    border: none;
    cursor: pointer;
}

.buttonDevis:hover {
    background-color: rgb(233, 233, 233);
    transition: .5s;
}



.googleMapReact {
  height: 64vh;
  width: 80%;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
       filter: grayscale(100%);
}


.mapGoogle {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 16%;
}

@media screen and (max-width: 960px) {
  .contact-text {
    font-size: 12px;
    line-height: 23px !important;
  }
  .mapGoogle {
  grid-template-columns: repeat(1, 1fr) !important;

  }
  .googleMapReact {
    width: 95% !important;
  }
}

.objectifMontre {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 88%;
}


.montre{
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    width: 400px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    overflow: hidden;
    box-shadow: 0 0 20px black;
}

.montre figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}

.montre figure:hover::before {
	-webkit-animation: shine 2s;
	animation: shine 2s;
}

.montre img{
    max-width: 100%;
    -webkit-transform: rotate(-45deg) scale(1.42);
            transform: rotate(-45deg) scale(1.42);
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

@media screen and (max-width: 1200px) {
	.montre {
		display: none !important;
	}
	.objectifMontre {
		grid-template-columns: auto !important;
	}
}

.bijouxImage {
    max-width: 100%;
    width: 500px;
    max-height: 300px;
}


.titleBijouxImage {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1204px) {
    #bijouxSection {
        margin-top: 500px !important;
    }

    .bouclesOrreilles {
        overflow: auto;
        height: 80%;
    }

    .Bracelets {
        overflow: auto;
        height: 80%;
    }
}

.sessionBijoux {
    display: grid;
    grid-gap: 20px;
    margin-top: 100px;
    grid-template-columns: repeat(2, 1fr);
    padding: 116px 40px 21px 51px;
}

@media screen and (max-width: 960px) {
    figcaption {
        width: 100% !important;
        font-size: 12px !important;
    }

    .bijouxImage {
        max-width: 76% !important;
    }
    .sessionBijoux {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .titleBijouxImage {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .bouclesOrreilles {
        overflow: inherit;
    }

    .Bracelets {
        overflow: inherit;
    }
}
@media screen and (max-width: 960px) {
    .imgSacaMain {
        width: 90%;
    }
}
.privacy h1 {
    font-family: bebas neue;
    font-size: 46px;
    text-align: center;
    color: rgb(241, 239, 223);
    margin: 0;
    padding: 0 0 6px 0;
  }
  .privacy h2 {
    font-family: bebas neue;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: rgb(241, 239, 223);
    margin: 0;
    padding: 40px 0 0 0;
  }
  .privacy p {
    font-family: open sans;
    font-size: 13px;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 10px 0 0 0;
    line-height: 30px;
  }
